<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Exercises</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-row v-show="showSearch" align="center" style="max-width: 300px">
        <v-text-field
          class="search"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search Exercise"
          v-model="filter.search"
          @input="search"
        ></v-text-field>
      </v-row>

      <v-skeleton-loader
        type="actions"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <template v-else>
        <v-select
          class="ml-10"
          hide-details
          dense
          item-text="label"
          item-value="value"
          v-model="defaultFilter"
          :items="filterBy"
          style="max-width: 200px"
          @change="filterExercise"
        />

        <v-btn
          depressed
          class="ml-2"
          :color="sortOrder ? 'primary' : ''"
          @click="
            sortOrder = true
            filterExercise()
          "
        >
          <v-icon>{{ icons.arrowUp }}</v-icon>
        </v-btn>
        <v-btn
          depressed
          class="ml-2"
          :color="!sortOrder ? 'primary' : ''"
          @click="
            sortOrder = false
            filterExercise()
          "
        >
          <v-icon>{{ icons.arrowDown }}</v-icon>
        </v-btn>

        <v-btn class="ml-5" icon @click="showSearchField">
          <img src="@/assets/icons/search.svg" alt height="17.7px" />
        </v-btn>
      </template>
    </v-app-bar>

    <div
      class="mx-4"
      id="venue-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <div class="d-flex my-6">
        <v-select
          class="mr-2"
          dense
          item-text="label"
          item-value="value"
          placeholder="Filter Exercise Category"
          :items="exercise_category_details"
          v-model="exercise_category_id"
          style="max-width: 250px"
          @change="filterExerciseCategory"
        />
        <v-spacer></v-spacer>
        <template v-if="!loading">
          <v-btn
            color="primary"
            @click="$router.push({ name: 'exercises.create' })"
            ><v-icon left dark> {{ icons.add }} </v-icon>Add Exercise</v-btn
          >
        </template>
      </div>
      <v-simple-table>
        <thead>
          <tr>
            <th width="15%" class="text-left">Name</th>
            <th width="15%" class="text-left">Description</th>
            <th width="15%" class="text-left">Duration</th>
            <th width="15%" class="text-left">Exercise Category</th>
            <th width="15%" class="text-left">Date Created</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="exercise in exercises"
            :key="exercise.id"
            class="clickable"
            @click="
              $router.push({
                name: 'exercises.details',
                params: { id: exercise.id },
              })
            "
          >
            <td>{{ exercise.name }}</td>
            <td>{{ exercise.description }}</td>
            <td>
              {{
                String(exercise.duration_minutes).padStart(2, '0') +
                ':' +
                String(exercise.duration_seconds).padStart(2, '0')
              }}
            </td>
            <td v-if="exercise.exercise_category">
              {{ exercise.exercise_category.category_name }}
            </td>
            <td v-else></td>
            <td>{{ exercise.addedDate }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import { each } from 'lodash'
import { mapState, mapActions, mapMutations } from 'vuex'
import {
  mdiDotsVertical,
  mdiUnfoldMoreHorizontal,
  mdiMagnify,
  mdiAccountCircleOutline,
  mdiChevronRight,
  mdiFileExcel,
  mdiPlus,
  mdiArrowUp,
  mdiArrowDown,
} from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'

export default {
  name: 'Exercises',

  mixins: [ControlsMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      loading: false,
      showSearch: false,
      filter: {
        search: '',
      },
      icons: {
        settings: mdiDotsVertical,
        sort: mdiUnfoldMoreHorizontal,
        search: mdiMagnify,
        view: mdiAccountCircleOutline,
        arrow: mdiChevronRight,
        export: mdiFileExcel,
        add: mdiPlus,
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
      },
      snackbar: {
        show: false,
        message: null,
        color: '',
      },
      exercise_category_details: [],
      exercise_category_id: '',
      defaultFilter: 'name',
      sortOrder: true,
      filterBy: [
        {
          value: 'name',
          label: 'Exercise Name',
        },
        {
          value: 'duration',
          label: 'Duration',
        },
        {
          value: 'exercise_category_id',
          label: 'Exercise Category',
        },
        {
          value: 'created_at',
          label: 'Date Created',
        },
      ],
    }
  },

  created() {
    this.clearExerciseList()
  },

  computed: {
    ...mapState({
      exercises: (state) => state.exercise.list,
      listMeta: (state) => state.exercise.listMeta,
      exerciseItemCategory: (state) => state.exercise.exerciseItemCategory,
    }),

    paginationLength() {
      return (this.listMeta.total / this.listMeta.per_page) | 0
    },
  },

  mounted() {
    this.fetchExercises()
    this.getExerciseItemsCategory()
  },

  methods: {
    ...mapActions({
      getExercises: 'exercise/getExercises',
      getExerciseItemCategory: 'exercise/getExerciseItemCategory',
    }),

    ...mapMutations({
      clearExerciseList: 'exercise/clearExerciseList',
    }),

    pageChanged(page) {
      this.fetchExercises(page)
    },

    async getExerciseItemsCategory() {
      this.loading = true
      await this.getExerciseItemCategory()
      each(this.exerciseItemCategory.exercise_category, (value, key) => {
        this.exercise_category_details.push({
          label: value.category_name,
          value: value.id,
        })
      })
      this.loading = false
    },

    filterExercise(filterValue) {
      this.clearExerciseList()
      this.fetchExercises(null, filterValue)
    },

    filterExerciseCategory(value) {
      this.clearExerciseList()
      this.fetchExercises(null, value)
    },

    loadMore() {
      if (
        this.listMeta &&
        this.listMeta.current_page < this.listMeta.last_page
      ) {
        this.fetchExercises(this.listMeta.current_page + 1)
      }
    },

    search: debounce(function () {
      this.clearExerciseList()
      this.fetchExercises()

      setTimeout(() => {
        this.$refs.searchField.$refs.input.focus()
      }, 500)
    }, 1600),

    async fetchExercises(page = 1) {
      if (this.loading) return
      let params = {
        page,
        sort: this.sortOrder
          ? `${this.defaultFilter}`
          : `-${this.defaultFilter}`,
        exercise_category_id: this.exercise_category_id,
      }

      if (this.filter.search) {
        params.search = this.filter.search
      }
      this.loading = true
      await this.getExercises(params)
      this.loading = false
    },

    async showSearchField() {
      this.showSearch = !this.showSearch
      await this.$nextTick()
      this.$refs.searchField.$refs.input.focus()
    },
  },
}
</script>
